import React, { createContext, useContext, useState, useCallback, useRef } from "react";

const ConfirmationModalContext = createContext();

export const useConfirmationModal = () => {
    return useContext(ConfirmationModalContext);
};

export const ConfirmationModalProvider = ({ children }) => {
    const confirmationModalButtonRef = useRef(null);
    const [message, setMessage] = useState("Are you sure?");
    const [onConfirm, setOnConfirm] = useState(() => () => { });

    const showConfirmation = useCallback((customMessage, onConfirmCallback) => {
        setMessage(customMessage || "Are you sure?");
        setOnConfirm(() => onConfirmCallback);
        confirmationModalButtonRef.current.click();
    }, []);

    const hideConfirmation = () => {
        confirmationModalButtonRef.current.click();
    };

    return (
        <ConfirmationModalContext.Provider value={{ showConfirmation }}>
            {children}
            <button className="d-none" id="confirmationModalButton" ref={confirmationModalButtonRef} data-bs-toggle="modal" data-bs-target="#confirmationModal">confirmationModalButton</button>
            <div className="modal fade" id="confirmationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmationModalLabel">Confirmation</h5>
                        </div>
                        <div className="modal-body">
                            {message}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={hideConfirmation}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={() => { onConfirm(); hideConfirmation(); }}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ConfirmationModalContext.Provider>
    );
};
