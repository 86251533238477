import { Link } from "react-router-dom";
import "./Carousel.css"; // Assuming you create a separate CSS file for the styles
import ServerUrlService from "../../services/serverUrlService";

const Carousel = ({ images, maxHeight }) => {
  const carouselStyle = {
    maxHeight: maxHeight ? `${maxHeight}px` : "auto",
    overflow: "hidden"
  };

  return (
    <div style={{ marginLeft: "-15px", marginRight: "-12px" }}>

      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" style={carouselStyle}>
        <div className="carousel-indicators">
          {images.map((image, index) => (
            <button key={index} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"} aria-label={`Slide ${index + 1}`}></button>
          ))}
        </div>
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img src={ServerUrlService.getFileURL(image.imageUrl)} className="d-block w-100 slider-item" alt={image.alt || `Slide ${index + 1}`} style={{ maxHeight: maxHeight ? `${maxHeight}px` : "auto" }} />
              {image.content && (
                <div className="carousel-caption custom-shape d-none d-md-block">
                  <h5>{image.header}</h5>
                  <p>{image.content}</p>
                  <Link to={`${image.linkTo}`} className="btn btn-primary">See More</Link>
                </div>
              )}
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
