import { useEffect, useRef, useState } from "react";
import { useConfirmationModal } from "../contexts/confirmationModalContext/ConfirmationModalContext";
import CustomNavLink from "../custom/CustomNavLink";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import CategoryService from "../../services/categoryService";

const Nav = () => {
    const { showConfirmation } = useConfirmationModal();
    const darkmodeToggleRef = useRef(null);
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const isDarkMode = localStorage.getItem("theme") === "dark";
        darkmodeToggleRef.current.checked = isDarkMode;

        const fetchCategories = async () => {
            const categoryData = await CategoryService.getAllCategories();
            setCategories(categoryData);
        };
        fetchCategories();
    }, []);

    const handleLogoutClicked = () => {
        showConfirmation("Are you sure you wish to log out?", () => {
            console.log("Logout");
            AuthService.logout();
        });
    }

    const handleThemeToggle = e => {
        const isDarkMode = e.target.checked;
        const htmlElement = document.documentElement; // This is the <html> element

        // Apply the theme based on the switch state
        if (isDarkMode) {
            htmlElement.setAttribute("data-bs-theme", "dark");
            localStorage.setItem("theme", "dark");
        } else {
            htmlElement.setAttribute("data-bs-theme", "light");
            localStorage.setItem("theme", "light");
        }
    };

    const handleSearchFormSubmit = e => {
        e.preventDefault();
        const form = e.target;
        const searchValue = form.search.value;
        if (searchValue.trim().length > 0) {
            console.log("?");
            form.reset();
            navigate(`/search/${searchValue}`);
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary border-bottom">
                <div className="container">
                    <NavLink className="navbar-brand" to="/"><span className="text-seqtor">SEQTOR</span> Costumer Portal</NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse d-lg-flex justify-content-lg-between" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Product Lines
                                </a>
                                <ul className="dropdown-menu" style={{ zIndex: 1051 }}>
                                    {
                                        categories?.map((c, i) => (
                                            <li key={i}><NavLink className="dropdown-item" to={`/category/${c.id}`}>{c.name}</NavLink></li>

                                        ))
                                    }
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><NavLink className="dropdown-item" to="/contact">Need something specific?</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/news">News</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/toolpool">Tool Pool</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/testenvironments">Test Environments</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/contact">Contact</NavLink>
                            </li>
                        </ul>

                        <form className="d-flex" role="search" onSubmit={handleSearchFormSubmit}>
                            <input className="form-control me-2" type="search" placeholder="Search" name="search" aria-label="Search" required />
                            <button className="btn btn-outline-success" type="submit">Search</button>
                        </form>

                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <CustomNavLink className="nav-link dropdown-toggle" to="/profile/*" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-person-circle" style={{ fontSize: "1.5em" }}></i>
                                </CustomNavLink>
                                <ul className="dropdown-menu dropdown-menu-end" style={{ zIndex: 1052 }}>
                                    <li><NavLink className="dropdown-item" to="/profile">Profile</NavLink></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li className="dropdown-item" onClick={(e) => e.stopPropagation()}>
                                        <label className="form-check-label">
                                            Light / Dark Mode
                                        </label>
                                        <div className="form-check form-switch ps-0 d-flex justify-content-center align-items-center pt-3">
                                            <input className="form-check-input m-0" type="checkbox" id="themeSwitch" ref={darkmodeToggleRef} style={{ transform: 'scale(1.25)' }} onChange={handleThemeToggle} />
                                        </div>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><button className="dropdown-item" onClick={handleLogoutClicked}>Logout</button></li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    );
};

export default Nav;