import React, { createContext, useContext, useState, useCallback, useRef } from "react";

const InformationModalContext = createContext();

export const useInformationModal = () => {
    return useContext(InformationModalContext);
};

export const InformationModalProvider = ({ children }) => {
    const informationModalButtonRef = useRef(null);
    const [message, setMessage] = useState("Are you sure?");
    const [onConfirm, setOnConfirm] = useState(() => () => { });

    const showInformationModal = useCallback((customMessage, onConfirmCallback) => {
        setMessage(customMessage || "Are you sure?");
        setOnConfirm(() => onConfirmCallback);
        informationModalButtonRef.current.click();
    }, []);

    const hideConfirmation = () => {
        informationModalButtonRef.current.click();
    };

    return (
        <InformationModalContext.Provider value={{ showInformationModal }}>
            {children}
            <button className="d-none" id="informationModalButton" ref={informationModalButtonRef} data-bs-toggle="modal" data-bs-target="#informationModal">informationModalButton</button>
            <div className="modal fade" id="informationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="informationModalLabel">Information</h5>
                        </div>
                        <div className="modal-body">
                            {message}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => { onConfirm(); hideConfirmation(); }}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </InformationModalContext.Provider>
    );
};
