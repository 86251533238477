import ProductCategoryNav from "./ProductCategoryNav";
import ProductFront from "./ProductFront";


import { useState, useEffect } from "react";
import ProductService from "../../services/productService";
import CategoryService from "../../services/categoryService";
import { useParams } from "react-router-dom";

const ProductCategory = () => {

    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState(undefined)
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const data = await ProductService.getByCategory(id);
                setProducts(data);
                const category = await CategoryService.getCategoryById(id);
                setCategory(category);
            } catch (error) {
                console.error('Error fetching products', error);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();    
    }, [id]);

    if (loading) return <p>Loading products...</p>;

    return (
        <div className="container">
            <ProductCategoryNav category={category} products={products} />
            <section className="row gy-3 mb-5">
                {
                    products.map((p, i) => (
                        <ProductFront key={i} product={p} />

                    ))
                }
            </section>
        </div>
    );
};

export default ProductCategory;