const LocalStorage = {
    // Save user login information
    saveLoginInfo: (email, password) => {
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userPassword", password);
    },

    // Check if login information exists
    isUserLoggedInfoSaved: () => {
        const email = localStorage.getItem("userEmail");
        const password = localStorage.getItem("userPassword");
        return email !== null && password !== null;
    },

    // Get user login information
    getLoginInfo: () => {
        return {
            userEmail: localStorage.getItem("userEmail"),
            userPassword: localStorage.getItem("userPassword")
        };
    },

    // Clear user login information
    clearLoginInfo: () => {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userPassword");
    },

    isUserLoggedIn: () => {
        return localStorage.getItem("token") !== null;
    }
};

export default LocalStorage;
