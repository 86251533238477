import api from './api';

const AboutService = {
  getAboutInfo: async () => {
    const response = await api.get('/api/about/1');
    return response.data;
  },
  
  updateAbout: async (id, aboutData) => {
    const response = await api.put(`/api/about/${id}`, aboutData);
    return response.data;
  }
};

export default AboutService;
