import Nav from "../nav/Nav";
import { Outlet } from "react-router-dom";

const MainHub = () => {
    return (
        <>
            <Nav />
            <main>
                <Outlet />
            </main>
        </>
    );
};

export default MainHub;