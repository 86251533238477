import Login from "../Login/Login";
import NotFound from "../NotFound";
import Home from "../home/Home";
import Footer from "./Footer";
import LocalStorage from "../../LocalStorage";
import MainHub from "../mainHub/MainHub";
import ProductCategory from "../productCategory/ProductCategory";
import About from "../about/About";
import Contact from "../contact/Contact";
import Profile from "../profile/Profile";
import ProductDetails from "../productDetails/ProductDetails";

import { Routes, Navigate, Route } from "react-router-dom";
import SearchResults from "../searchResults/SearchResults";
import News from "../news/News";
import NewsDetails from "../news/NewsDetails";


const Layout = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={LocalStorage.isUserLoggedIn() ? <MainHub />: <Navigate to="/login" />}>
                    <Route index element={<Home />} />
                    <Route path="category/:id" element={<ProductCategory />} />
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="news" element={<News />} />
                    <Route path="news/:newsId" element={<NewsDetails />} />
                    <Route path="category/:categoryId/:productId" element={<ProductDetails />} />
                    <Route path="search/:searchValue" element={<SearchResults />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="/login" element={LocalStorage.isUserLoggedIn() ? <Navigate to="/" /> : <Login />} />
            </Routes>
            <Footer />
        </>
    );
};

export default Layout;