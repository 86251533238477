import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Link } from "react-router-dom";
import ContactService from "../../services/contactService";

// Set custom icon for the marker
const markerIcon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const Contact = () => {
    const position = [56.408469, 10.924047]; // Coordinates for Copenhagen, Denmark

    const [contact, setContact] = useState(null);

    useEffect(() => {
      const fetchContact = async () => {
        const contactData = await ContactService.getContact();
        setContact(contactData);
      };
      fetchContact();
    }, []);

    if (!contact) return <span>Loading...</span>

    return (
            <div className="container mt-3">
                <h1>Contact Us</h1>
                <div className="row mt-4">
                    <div className="col-lg-6">
                        <h3>Contact Information</h3>
                        <p><strong>{contact.companyName}</strong></p>
                        <p>{contact.address}</p>
                        <p>{contact.city}</p>
                        <p><strong>Phone:</strong> {contact.phone}</p>
                        <p><strong>Email:</strong> {contact.email}</p>
                    </div>
                    <div className="col-lg-6">
                        <h3>Our Location</h3>
                        <div className="card">
                            <div className="card-body">
                                <MapContainer center={position} zoom={16} style={{ height: "400px", width: "100%" }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                                    />
                                    <Marker position={position} icon={markerIcon}>
                                        <Popup>
                                            SEQTOR Headquarters<br />Copenhagen, Denmark.
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <h3 className="mt-5">Get in Touch</h3>
                    <p className="lead mt-4">
                        {contact.message}
                    </p>
                    <div className="text-center mt-4">
                        <Link to="/profile" className="btn btn-lg btn-outline-primary px-5 py-3 shadow-lg rounded" style={{ textDecoration: 'none', fontSize: '1.2rem', transition: 'background-color 0.3s, color 0.3s' }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#0d6efd'} onMouseLeave={e => e.currentTarget.style.backgroundColor = ''}>
                            Go to Your Profile
                        </Link>
                    </div>
                </div>
            </div>
    );
}

export default Contact;
