import api from './api';

const TechGalleryItemsService = {
    getAll: async () => {
        const response = await api.get('/api/techGalleryItems');
        return response.data;
    },

    getById: async (id) => {
        const response = await api.get(`/api/techGalleryItems/${id}`);
        return response.data;
    },

    create: async (news) => {
        const response = await api.post('/api/techGalleryItems', news);
        return response.data;
    },

    update: async (id, news) => {
        const response = await api.put(`/api/techGalleryItems/${id}`, news);
        return response.data;
    },

    delete: async (id) => {
        const response = await api.delete(`/api/techGalleryItems/${id}`);
        return response.data;
    },
    toggleEnabled: async (id) => {
        const response = await api.patch(`/api/techGalleryItems/${id}/ToggleEnabled`);
        return response.data;
    }
};

export default TechGalleryItemsService;
