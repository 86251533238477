import api from './api';

const NewsService = {
    getAll: async () => {
        const response = await api.get('/api/news');
        return response.data;
    },

    getById: async (id) => {
        const response = await api.get(`/api/news/${id}`);
        return response.data;
    },

    create: async (news) => {
        const response = await api.post('/api/news', news);
        return response.data;
    },

    update: async (id, news) => {
        const response = await api.put(`/api/news/${id}`, news);
        return response.data;
    },

    delete: async (id) => {
        const response = await api.delete(`/api/news/${id}`);
        return response.data;
    },
    toggleEnabled: async (id) => {
        const response = await api.patch(`/api/news/${id}/ToggleEnabled`);
        return response.data;
    }
};

export default NewsService;
