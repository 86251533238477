import { useEffect, useState } from "react";
import Logo from "../../content/seqtorlogowhitefont.png";
import AboutService from "../../services/aboutService";
import useSanitizedHtml from "../hooks/useSanitizedHtml";
import ServerUrlService from "../../services/serverUrlService";

const About = () => {
  const [aboutInfo, setAboutInfo] = useState(null);

  useEffect(() => {
    const fetchAboutInfo = async () => {
      const data = await AboutService.getAboutInfo();
      setAboutInfo(data);
    };
    fetchAboutInfo();
  }, []);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-lg-4 mb-4">
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <img src={ServerUrlService.getFileURL(aboutInfo?.logo_Url)} alt="SEQTOR Logo" className="img-fluid" style={{ maxWidth: "200px" }} />
          </div>
        </div>
        <div className="col-lg-8">
          <h1>About SEQTOR</h1>
          <div className="mt-4" dangerouslySetInnerHTML={useSanitizedHtml(aboutInfo?.content)}>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <h2>References</h2>
          <p>
            SEQTOR has had the privilege of working with a number of prestigious organizations, including:
          </p>
          <ul className="list-group">
            {
              aboutInfo?.references.map((r, i) => (
                <li key={i} className="list-group-item">{r.content}</li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
