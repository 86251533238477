import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NewsService from "../../services/newsService";
import ServerUrlService from "../../services/serverUrlService";

const News = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const newsData = await NewsService.getAll();
            setNews(newsData);
        };
        fetchCategories();
    }, []);

    return (
        <div className="container mt-4">
            <div className="container mt-4">
                {news.map((item) => (
                    <div key={item.id} className="card mb-3">
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img
                                    src={ServerUrlService.getFileURL(item.imageUrl)}
                                    alt={item.title}
                                    className="img-fluid rounded-start"
                                    style={{ height: "100%", objectFit: "cover" }}
                                />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{item.date}</h6>
                                    <p className="card-text">
                                        {item.content.length > 100
                                            ? item.content.substring(0, 100) + "..."
                                            : item.content}
                                    </p>
                                    <Link to={`/news/${item.id}`} className="btn btn-primary">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default News;
