import React, { createContext, useState, useContext } from "react";
import LoadingScreen from "./LoadingScreen";

const LoadingContext = createContext();

export const useLoading = () => {
    return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const setLoading = (loading) => setIsLoading(loading);

    return (
        <LoadingContext.Provider value={{ isLoading, setLoading }}>
            {children}
            <LoadingScreen show={isLoading} />
        </LoadingContext.Provider>
    );
};
