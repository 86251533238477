import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="container text-center mt-3">
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                    <h1 className="display-1 text-primary fw-bold">404</h1>
                    <p className="h4 text-secondary">Oops! Page not found</p>
                    <p className="mb-4 text-muted">The page you are looking for does not exist, was removed, or is temporarily unavailable.</p>
                    <Link to="/" className="btn btn-primary btn-lg">Go Back Home</Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;