import "./App.scss";
import { Popover } from "bootstrap/dist/js/bootstrap.esm.min.js";
import Layout from "./components/layout/Layout";
import { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

const App = () => {

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    const themeSwitch = document.getElementById("themeSwitch");
    const htmlElement = document.documentElement;
  
    if (savedTheme === "light") {
      htmlElement.setAttribute("data-bs-theme", "light");
      if (themeSwitch) themeSwitch.checked = false;
    } else {
      htmlElement.setAttribute("data-bs-theme", "dark");
      if (themeSwitch) themeSwitch.checked = true;
    }
  }, []);
  


  return (
    <>
      <Router>
        <Layout />
        <InitBootstrapPopoversAndScrollToTop />
      </Router>
    </>
  );
}

const InitBootstrapPopoversAndScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    Array.from(document.querySelectorAll("button[data-bs-toggle='popover']"))
      .forEach(popoverNode => new Popover(popoverNode));
    window.scrollTo(0, 0);
  }, [location]);

  return (<></>);
};

export default App;