import "./Login.scss";
import StaticImages from "../../StaticImages";
import LocalStorage from "../../LocalStorage";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useRef, useState } from "react";
import { useLoading } from "../contexts/loadingContext/LoadingContext";
import { useInformationModal } from "../contexts/informationModalContext/InformationModalContext";
import AuthService from "../../services/authService";
import ReelsContainer from "../reel/ReelsContainer";
import Gallery from "../gallery/Gallery";
import TechGalleryItemsService from "../../services/techGalleryItemsService";

const Login = () => {
    const [currentTheme] = useState(localStorage.getItem("theme"));
    const [rememberMe, setRememberMe] = useState(LocalStorage.isUserLoggedInfoSaved());
    const [loginInfo, setLoginInfo] = useState(
        rememberMe ? LocalStorage.getLoginInfo() : { userEmail: "", userPassword: "" }
    );
    const { setLoading } = useLoading();
    const { showInformationModal } = useInformationModal();
    const [captchaToken, setCaptchaToken] = useState(null);
    const recaptchaRef = useRef(null);
    const [techGalleryItems, setTechGalleryItems] = useState([]);

    useEffect(() => {
        const fetchTechGalleryItems = async () => {
            const techItems = await TechGalleryItemsService.getAll();
            setTechGalleryItems(techItems);
        };
        fetchTechGalleryItems();
    }, []);

    const handleCaptchaChange = (token) => setCaptchaToken(token);
    const handleFormChanged = (e) => {
        if (e.target.id !== "rememberMe") {
            setLoginInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
        }
    };
    const handleRememberMeChanged = () => setRememberMe((prev) => !prev);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!captchaToken) {
            showInformationModal("You need to complete the captcha to login.", () => { });
            return;
        }
        setLoading(true);
        try {
            let user = await AuthService.login(
                loginInfo.userEmail,
                loginInfo.userPassword,
                captchaToken
            );
            console.log(user);
            await new Promise((resolve) => setTimeout(resolve, 1500));
        } catch (err) {
            alert("Error: " + err);
        } finally {
            if (rememberMe) LocalStorage.saveLoginInfo(loginInfo.userEmail, loginInfo.userPassword);
            setLoading(false);
            recaptchaRef.current.reset();
            window.location.reload();
        }
    };

    return (
        <div className="container-fluid">
            <div className="row flex-column flex-md-row">
                {/* Left Column */}
                <div className="col-12 col-md-4 col-lg-4 d-flex flex-column align-items-center py-5">
                    <ReelsContainer />
                </div>

                {/* Center Column */}
                <div className="col-12 col-md-4 col-lg-4 d-flex flex-column justify-content-between align-items-center py-5">
                    <h1 className="text-center mb-4">
                        <span className="display-1">SEQTOR</span>
                        <br />
                        <span className="fw-bold" style={{ color: "#b5985a" }}>MISSION WITH VISION</span>
                    </h1>
                    <img
                        src={StaticImages.seqtor_bird}
                        alt="Logo"
                        className="login-logo-bird mb-4"
                        style={{ maxWidth: "80%" }}
                    />
                    <div className="text-center">
                        <img
                            src={StaticImages.corvi_logo}
                            className="rounded mb-3"
                            style={{ height: "85px" }}
                            alt="CORVI Logo"
                        />
                        <p className="fs-5">
                            SEQTOR is proud to operate in close collaboration with our partner,
                            CORVI.
                        </p>
                    </div>
                </div>

                {/* Right Column */}
                <div className="col-12 col-md-4 col-lg-4 d-flex flex-column align-items-center py-5">
                    <Gallery items={techGalleryItems} />
                </div>
            </div>

            {/* Login Form (commented for now) */}
            {/* <form
                onChange={handleFormChanged}
                onSubmit={handleFormSubmit}
                className="w-100 mt-5 px-3"
            >
                <div className="mb-3">
                    <label htmlFor="userEmail" className="form-label">
                        Email address
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="userEmail"
                        defaultValue={loginInfo.userEmail}
                        placeholder="Enter email"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="userPassword" className="form-label">
                        Password
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="userPassword"
                        defaultValue={loginInfo.userPassword}
                        placeholder="Password"
                        required
                    />
                </div>
                <div className="mb-3 form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={handleRememberMeChanged}
                    />
                    <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                    </label>
                </div>
                <div className="mb-3 recaptcha-container">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="your-site-key"
                        onChange={handleCaptchaChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                    Login
                </button>
            </form> */}
        </div>
    );
};

export default Login;
