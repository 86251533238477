import { useState } from "react";
import Reel from "./Reel";
import NewsService from "../../services/newsService";
import { useEffect } from "react";

const ReelsContainer = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            const newsData = await NewsService.getAll();
            setNews(newsData);
        };
        fetchNews();
    }, []);

    return (
        <>
            <h2>SEQTOR {new Date().getFullYear()} - {new Date().getFullYear() + 1} </h2>
            <div className="reel-container">
                {news.map((n, index) => (
                    <Reel key={index} title={n.title} content={n.content} imageUrl={n.imageUrl} mediaType={n.mediaType} />
                ))}
            </div>
        </>
    );
};

export default ReelsContainer;
