import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

const CustomNavLink = ({ to, children, className, ...props }) => {
    const location = useLocation();

    const isActive = location.pathname.startsWith(to.replace("*", ""));

    return (
        <NavLink
            to={to.replace("*", "")}
            className={clsx(className, { "active": isActive })}
            {...props}
        >
            {children}
        </NavLink>
    );
};

export default CustomNavLink;
