// src/services/companyService.js
import api from "./api";

const CompanyService = {
  // Fetch all company
  getAll: async () => {
    const response = await api.get("/api/company");
    return response.data;
  },
  
  // Fetch a company by ID
  getById: async (id) => {
    const response = await api.get(`/api/company/${id}`);
    return response.data;
  },
  
  // Create a new company (with optional logo upload)
  create: async (companyData, logo) => {
    const formData = new FormData();
    formData.append("Name", companyData.name);
    formData.append("Liaison", companyData.liaison);
    formData.append("Email", companyData.email);
    formData.append("Password", companyData.password);
    formData.append("UserLevelId", companyData.userLevelId);
    formData.append("Enabled", companyData.enabled);

    if (logo) {
      formData.append("logo", logo);
    }

    const response = await api.post("/api/company", formData);
    return response.data;
  },

  // Update an existing company (with optional logo upload)
  update: async (id, companyData, logo) => {
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("Name", companyData.name);
    formData.append("Liaison", companyData.liaison);
    formData.append("Email", companyData.email);

    // Include Password only if it"s provided (optional update)
    if (companyData.password) {
      formData.append("Password", companyData.password);
    }

    formData.append("UserLevelId", companyData.userLevelId);
    formData.append("Enabled", companyData.enabled);

    // Handle logo upload if provided
    if (logo) {
      formData.append("logo", logo);
    }

    const response = await api.put(`/api/company/${id}`, formData);
    return response.data;
  },

  // Delete a company by ID
  delete: async (id) => {
    const response = await api.delete(`/api/company/${id}`);
    return response.data;
  },
  
  // Toggle the "Enabled" status of a company
  toggleEnabled: async (id) => {
    const response = await api.patch(`/api/company/${id}/ToggleEnabled`);
    return response.data;
  }
};

export default CompanyService;
