import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ServerUrlService from "../../services/serverUrlService";

const ProductFront = ({ product }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(elementRef.current);
                }
            },
            { threshold: 0.1 } // Adjust this threshold as needed
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);

    return (
        <article ref={elementRef} className={`col-12 ${isVisible ? "animate__animated animate__fadeInUp" : "opacity-0"}`} id={`product${product.id}`}>
            <div className="card custom-shape">
                <img src={ServerUrlService.getFileURL(product.imageUrl)} className="card-img-top rounded-0" alt={product.name} />
                <div className="card-body">
                    <h5 className="card-title">{product.name}</h5>
                    <p className="card-text">{product.description}</p>
                    <Link to={`/category/${product.category.id}/${product.id}`} className="btn btn-primary">Read More</Link>
                </div>
            </div>
        </article>
    );
};

export default ProductFront;