import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewsService from "../../services/newsService";
import ServerUrlService from "../../services/serverUrlService";

const NewsDetails = () => {
    const navigate = useNavigate();
    const { newsId } = useParams();
    const [news, setNews] = useState({});

    useEffect(() => {
        const fetchNewsItem = async () => {
            const newsData = await NewsService.getById(newsId);
            setNews(newsData);
        };
        fetchNewsItem();
    }, []);
    return (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-3" onClick={() => navigate("/news")}>
                &larr; Back
            </button>
            <div className="card">
                <img
                    src={ServerUrlService.getFileURL(news.imageUrl)}
                    alt={news.title}
                    className="card-img-top"
                    style={{ maxHeight: "400px", objectFit: "cover" }}
                />
                <div className="card-body">
                    <h3 className="card-title">{news.title}</h3>
                    <h6 className="card-subtitle mb-2 text-muted">{news.lastModified}</h6>
                    <p className="card-text mt-4">{news.content}</p>
                </div>
            </div>
        </div>
    );
};

export default NewsDetails;
