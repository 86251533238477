import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const SearchResultItem = ({ title, text, image }) => {

  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(elementRef.current);
        }
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div ref={elementRef} className={`col-sm-12 col-md-6 col-xl-4 ${isVisible ? "animate__animated animate__fadeInUp" : "opacity-0"}`}>
      <div className="card custom-shape">
        <img src={image ?? "https://placehold.co/500x320"} className="card-img-top rounded-0" alt="..." />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{text}</p>
          <Link to={`/category/1/1`} className="btn btn-primary">See More</Link>
        </div>
      </div>
    </div>
  );
};

export default SearchResultItem;
