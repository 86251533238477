import SliderItemService from "../../services/sliderItemsService";
import Carousel from "../carousel/Carousel";
import ProductCategories from "./ProductCategories";
import { useState,useEffect } from "react";

const Home = () => {
    const [sliderItems, setSliderItems] = useState([]);

    useEffect(() => {
        const fetchSliderItems = async () => {
          const data = await SliderItemService.getAllSliderItems();
          setSliderItems(data);
        };
        fetchSliderItems();
      }, []);

    return (
        <section className="container">
                <Carousel images={sliderItems} maxHeight={600} />
                <hr />
                <ProductCategories />
        </section>
    );
};

export default Home;