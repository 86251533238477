import React from "react";
import ServerUrlService from "../../services/serverUrlService";

const Reel = ({ imageUrl, mediaType, title, content }) => {
    return (
        <div className="reel">
            <div className="media">
                {mediaType.type === "Image" ? (
                    <img src={ServerUrlService.getFileURL(imageUrl)} alt="Reel Media" className="d-block" />
                ) : (
                    <video controls className="d-block">
                        <source src={ServerUrlService.getFileURL(imageUrl)} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                )}
            </div>
            <div className="content">
                <h5 className="mb-2">{title}</h5>
                {/* <p>{content}</p> */}
            </div>
        </div>
    );
};

export default Reel;
