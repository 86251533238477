import React from "react";
import ServerUrlService from "../../services/serverUrlService";

const GalleryItem = ({ thumbnail, large, onClick }) => {
    return (
        <div className="col">
            <img
                src={ServerUrlService.getFileURL(thumbnail)}
                alt={ServerUrlService.getFileURL(large)}
                className="img-fluid rounded"
                onClick={onClick}
                style={{ cursor: "pointer", objectFit: "cover" }}
            />
        </div>
    );
};

export default GalleryItem;
