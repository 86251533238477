import React, { useState } from "react";
import GalleryItem from "./GalleryItem";
import ServerUrlService from "../../services/serverUrlService";

const Gallery = ({ items }) => {
    const [lightboxImage, setLightboxImage] = useState(null);
    const [lightboxTitle, setLightboxTitle] = useState(null);

    const openLightbox = (imageSrc, title) => {
        setLightboxImage(imageSrc);
        setLightboxTitle(title);
    };

    const closeLightbox = () => {
        setLightboxImage(null);
        setLightboxTitle(null);
    };

    const [tempImages] = useState([
        { image: "images/signal-2024-05-09-132736.jpg", title: "Dominator Rendering" },
        { image: "images/Dominator_Render.jpg", title: "Dominator Rendering" },
        { image: "images/signal-2024-11-13-143953_004.jpeg", title: "Special broadband Drone Antenna development to 12.7 Ghz" },
        { image: "images/signal-2024-11-13-143953_005.jpeg", title: "Special broadband Drone Antenna development to 12.7 Ghz" },
        { image: "images/signal-2024-11-13-143953_006.jpeg", title: "Special broadband Drone Antenna development to 12.7 Ghz" },
        { image: "images/signal-2024-11-13-143953_002.png", title: "10 GHz" },
        { image: "images/signal-2024-11-13-143953_003.png", title: "10 GHz Smith Chart" },
    ])

    return (
        <>
            <h2>SEQTOR Tech Corner</h2>
            <div className="gallery-container">
                {/* Image Grid */}
                {/* <div className="row row-cols-3 g-3">
                    {items.map((item) => (
                        <GalleryItem key={item.id} thumbnail={item.fileUrl} large={item.fileUrl} onClick={() => openLightbox(item.fileUrl, item.title)} />
                    ))}
                </div> */}

                <div className="row g-3">
                    <div className="col-md-6">
                        <GalleryItem thumbnail={tempImages[0].image} large={tempImages[0].image} onClick={() => openLightbox(tempImages[0].image, tempImages[0].title)} />
                    </div>
                    <div className="col-md-6">
                        <GalleryItem thumbnail={tempImages[1].image} large={tempImages[1].image} onClick={() => openLightbox(tempImages[1].image, tempImages[1].title)} />
                    </div>
                    <div className="col-md-4">
                        <GalleryItem thumbnail={tempImages[2].image} large={tempImages[2].image} onClick={() => openLightbox(tempImages[2].image, tempImages[2].title)} />
                    </div>
                    <div className="col-md-4">
                        <GalleryItem thumbnail={tempImages[3].image} large={tempImages[3].image} onClick={() => openLightbox(tempImages[3].image, tempImages[3].title)} />
                    </div>
                    <div className="col-md-4">
                        <GalleryItem thumbnail={tempImages[4].image} large={tempImages[4].image} onClick={() => openLightbox(tempImages[4].image, tempImages[4].title)} />
                    </div>
                    <div className="col-md-6">
                        <GalleryItem thumbnail={tempImages[5].image} large={tempImages[5].image} onClick={() => openLightbox(tempImages[5].image, tempImages[5].title)} />
                    </div>
                    <div className="col-md-6">
                        <GalleryItem thumbnail={tempImages[6].image} large={tempImages[6].image} onClick={() => openLightbox(tempImages[6].image, tempImages[6].title)} />
                    </div>
                </div>

                {/* Lightbox/Modal */}
                {lightboxImage && (
                    <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} onClick={closeLightbox}>
                        <div className="modal-dialog modal-xl modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{lightboxTitle}</h5>
                                    <button type="button" className="btn-close" onClick={closeLightbox}></button>
                                </div>
                                <div className="modal-body text-center">
                                    <img src={ServerUrlService.getFileURL(lightboxImage)} alt="Large View" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
};

export default Gallery;
