import api from "./api";

const ContactService = {
  getContact: async () => {
    const response = await api.get("/api/contact/1");
    return response.data;
  },
  
  updateContact: async (id, contactData) => {
    const response = await api.put(`/api/contact/${id}`, contactData);
    return response.data;
  },
};

export default ContactService;