import api from './api';

const CategoryService = {
  getAllCategories: async () => {
    const response = await api.get('/api/category');
    return response.data;
  },
  
  getCategoryById: async (id) => {
    const response = await api.get(`/api/category/${id}`);
    return response.data;
  },
  
  createCategory: async (categoryData) => {
    const response = await api.post('/api/category', categoryData);
    return response.data;
  },
  
  updateCategory: async (id, categoryData) => {
    const response = await api.put(`/api/category/${id}`, categoryData);
    return response.data;
  },
  
  deleteCategory: async (id) => {
    const response = await api.delete(`/api/category/${id}`);
    return response.data;
  },
};

export default CategoryService;
