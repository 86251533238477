import api from './api';

const ProductService = {
  getAll: async () => {
    const response = await api.get('/api/product');
    return response.data;
  },

  getById: async (id) => {
    const response = await api.get(`/api/product/${id}`);
    return response.data;
  },

  create: async (product) => {
    const response = await api.post('/api/product', product);
    return response.data;
  },

  update: async (id, product) => {
    const response = await api.put(`/api/product/${id}`, product);
    return response.data;
  },

  delete: async (id) => {
    const response = await api.delete(`/api/product/${id}`);
    return response.data;
  },
  search: async (params) => {
    const response = await api.get('/api/product/search', { params });
    return response.data;
  },
  getByCategory: async (categoryId) => {
    const response = await api.get(`/api/product/bycategory/${categoryId}`);
    return response.data;
  }
};

export default ProductService;
