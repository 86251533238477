import "./ProductCategoryNav.scss";
import { useEffect, useState } from "react";

const ProductCategoryNav = ({ category, products = [] }) => {
    const [activeSection, setActiveSection] = useState("");
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("article");
            let currentSection = "";

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                if (window.scrollY >= sectionTop - 125) {
                    currentSection = section.getAttribute("id");
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className="navbar navbar-expand-lg bg-body-tertiary mb-3 position-sticky" style={{ top: 0, zIndex: 1050 }}>
                <div className="container-fluid">
                    <a className="navbar-brand text-seqtor" href="#">{category?.name}</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#categoryNavbar" aria-controls="categoryNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="categoryNavbar">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {
                                products.map((p, i) => (
                                    <li key={i} className="nav-item"><a className={`nav-link ${activeSection === `product${p.id}` ? "active" : ""}`} aria-current="page" href={`#product${p.id}`}>{p.name}</a></li>
                                ))
                            }
                        </ul>
                        <button className="btn btn-outline-primary" onClick={() => { window.scrollTo(0, 0) }}>&uarr;</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductCategoryNav;