import AuthService from "../../services/authService";
import CompanyService from "../../services/companyService"
import "./Profile.scss";
import { useEffect, useRef, useState } from "react";

const Profile = () => {
    const createFileFromUrl = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const contentType = response.headers.get("content-type");
            const fileName = url.split("/").pop(); // Extract file name from URL

            const file = new File([blob], fileName, { type: contentType });
            file.url = url; // Add the URL as a custom property to the File object

            return file;
        } catch (error) {
            console.error("Error creating file from URL:", error);
            return null;
        }
    };

    const [companyData, setCompanyData] = useState({
        logo: "https://placehold.co/500x320",
        name: "Example Company",
        liaison: "John Doe",
        email: "john.doe@example.com",
        messageHistory: [
            { sender: "owner", text: "Welcome to our service!", timestamp: "2024-01-01 12:00:00", file: null },
            { sender: "owner", text: "Feel free to contact us anytime.", timestamp: "2024-01-02 08:30:00", file: null },
            { sender: "user", text: "Can I get some more information?", timestamp: "2024-01-03 10:00:00", file: null },
            { sender: "owner", text: "Sure! Here’s more info.", timestamp: "2024-01-03 11:00:00", file: null },
        ],
    });

    const messageHistoryRef = useRef(null);
    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState(companyData.messageHistory);
    const [isLoading, setIsLoading] = useState(false);
    const [attachedFile, setAttachedFile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await CompanyService.getById(AuthService.getCurrentUser().CompanyId);
            setCompanyData(data);
        }

        fetchData();
    }, []);

    const scrollToBottom = () => {
        if (messageHistoryRef.current) {
            messageHistoryRef.current.scrollTo({
                top: messageHistoryRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    };

    const truncateString = (str, maxLength) => {
        const extension = str.slice(str.lastIndexOf("."));
        const baseNameLength = maxLength - extension.length - 3;

        if (str.length > maxLength) {
            return str.slice(0, baseNameLength) + "..." + extension;
        } else {
            return str;
        }
    }

    const handleSendMessage = () => {
        if (newMessage.trim() || attachedFile) {
            setIsLoading(true);

            setTimeout(() => {
                const newMessageData = {
                    text: newMessage,
                    timestamp: new Date().toLocaleString(),
                    file: attachedFile ? { name: attachedFile.name, url: URL.createObjectURL(attachedFile) } : null,
                };
                setMessages([...messages, newMessageData]);
                setNewMessage("");
                setAttachedFile(null);
                setIsLoading(false);
            }, 2000); // Simulate a delay of 2 seconds
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAttachedFile(file);
    };

    const renderFileIcon = (fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const isTextFile = fileExtension === "txt";

        if (isTextFile) {
            return <i className="bi bi-file-earmark-text" style={{ fontSize: "24px" }}></i>;
        }

        return <i className="bi bi-file-earmark" style={{ fontSize: "24px" }}></i>;
    };

    // Watch messages array, and scroll to bottom when it updates
    useEffect(() => {
        scrollToBottom();
    }, [messages, isLoading]);

    return (
        <div className="container mt-4">
            <div className="row mb-4">
                <div className="col-md-4">
                    <img alt="Company Logo" className="img-fluid" src={companyData.logo === undefined ? companyData.logo : "https://placehold.co/500x320"} />
                </div>
                <div className="col-md-8">
                    <div>
                        <h2>{companyData.name}</h2>
                        <p><strong>Contact Name:</strong> {companyData.liaison}</p>
                        <p><strong>Email:</strong> {companyData.email}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <h4>Message History</h4>
                    <div className="message-history" ref={messageHistoryRef}>
                        {messages.map((message, index) => (
                            <div key={index} className={`d-flex ${message.sender === "owner" ? "justify-content-start" : "justify-content-end"} mb-3`}>
                                <div className={`message-bubble ${message.sender === "owner" ? "owner-message" : "user-message"}`}>
                                    <p className="mb-1">{message.text}</p>
                                    {message.file && (
                                        <div className="mt-2">
                                            {message.file.name.endsWith(".jpg") || message.file.name.endsWith(".png") || message.file.name.endsWith(".jpeg") ? (
                                                <img src={message.file.url} alt="Attachment" className="img-thumbnail" style={{ maxHeight: "150px", maxWidth: "100%" }} />
                                            ) : (
                                                <div>
                                                    {renderFileIcon(message.file.name)}
                                                    <a href={message.file.url} download className="btn btn-link mt-2">
                                                        {truncateString(message.file.name, 24)}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <small className="d-block text-muted mt-1" style={{ textAlign: "right" }}>
                                        {message.timestamp}
                                    </small>
                                </div>
                            </div>
                        ))}
                        {isLoading && (
                            <div className="d-flex justify-content-center align-items-center py-4">
                                <div className="spinner-border text-primary" role="status"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h4>Write a New Message</h4>
                    <textarea className="form-control mb-3" rows="3" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder="Type your message here..." />
                    <input type="file" className="form-control mb-3" onChange={handleFileChange} />
                    <button className="btn btn-primary" onClick={handleSendMessage} disabled={isLoading}>
                        Send Message
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
