import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import CategoryService from "../../services/categoryService";
import Image from "../../Image";
import ServerUrlService from "../../services/serverUrlService";

const ProductCategories = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const categoryData = await CategoryService.getAllCategories();
            setCategories(categoryData);
        };
        fetchCategories();
    }, []);
    return (
        <section className="row gy-3">
            {
                categories.map((e, i) => (
                    <article key={i} className="col-sm-12 col-md-6 col-xl-4 animate__animated animate__fadeInLeft">
                        <div className="card custom-shape">
                            <img src={ServerUrlService.getFileURL(e.imageUrl)} className="card-img-top rounded-0" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">{e.name}</h5>
                                <p className="card-text">{e.description}</p>
                                <Link to={`/category/${e.id}`} className="btn btn-primary">See More</Link>
                            </div>
                        </div>
                    </article>
                ))
            }
        </section>
    );
};

export default ProductCategories;