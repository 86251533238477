import api from './api';

const SliderItemService = {
  getAllSliderItems: async () => {
    const response = await api.get('/api/sliderItem');
    return response.data;
  },
  
  getSliderItemById: async (id) => {
    const response = await api.get(`/api/sliderItem/${id}`);
    return response.data;
  },
  
  createSliderItem: async (sliderItemData) => {
    const response = await api.post('/api/sliderItem', sliderItemData);
    return response.data;
  },
  
  updateSliderItem: async (id, sliderItemData) => {
    const response = await api.put(`/api/sliderItem/${id}`, sliderItemData);
    return response.data;
  },
  
  deleteSliderItem: async (id) => {
    const response = await api.delete(`/api/sliderItem/${id}`);
    return response.data;
  },
  
  toggleEnabled: async (id) => {
    const response = await api.patch(`/api/sliderItem/${id}/ToggleEnabled`);
    return response.data;
  }
};

export default SliderItemService;
