import { jwtDecode } from "jwt-decode";
import api from "./api";

const AuthService = {
  login: async (email, password, reCaptchaToken) => {
    // Send email, password, and reCAPTCHA token to the login API endpoint
    const response = await api.post("/api/login", {
      email,
      password,
      reCaptchaToken,  // Include the reCAPTCHA token in the payload
    });

    // Extract token from the response and save it in localStorage
    const { token } = response.data;
    localStorage.setItem("token", token);

    // Optionally decode the token to get user data
    return jwtDecode(token);
  },

  logout: () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
    window.location.href = "/login";  // Redirect to login page
  },

  getCurrentUser: () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        return jwtDecode(token);
      }
    } catch (err) {
      return null;
    }
  },
};

export default AuthService;
