import React from "react";
import SearchResultItem from "./SearchResultItem";
import { useParams } from "react-router-dom";


import ph_img1 from "../../content/placeholderImages/product_placeholders/cat_1_prod_1.webp";
import ph_img2 from "../../content/placeholderImages/product_placeholders/cat_1_prod_2.webp";
import ph_img3 from "../../content/placeholderImages/product_placeholders/cat_1_prod_3.webp";
import ph_img4 from "../../content/placeholderImages/product_placeholders/cat_1_prod_4.webp";
import ph_img5 from "../../content/placeholderImages/product_placeholders/cat_1_prod_5.webp";


const SearchResults = () => {
  const { searchValue } = useParams();
  const products = [
    {
      title: "Product Title 1",
      text: "Some quick example text to build on the card title and make up the bulk of the card\"s content.",
      imageUrl: ph_img1
    },
    {
      title: "Product Title 2",
      text: "Some quick example text to build on the card title and make up the bulk of the card\"s content.",
      imageUrl: ph_img2
    },
    {
      title: "Product Title 3",
      text: "Some quick example text to build on the card title and make up the bulk of the card\"s content.",
      imageUrl: ph_img3
    },
    {
      title: "Product Title 4",
      text: "Some quick example text to build on the card title and make up the bulk of the card\"s content.",
      imageUrl: ph_img4
    },
    {
      title: "Product Title 5",
      text: "Some quick example text to build on the card title and make up the bulk of the card\"s content.",
      imageUrl: ph_img5
    },
    // Add more product objects here
  ];

  return (
    <section className="container mt-3">
      <h3>Showing results for: {searchValue}</h3>
      <div className="row gy-3">
        {products.map((product, index) => (
          <SearchResultItem
            key={index}
            title={product.title}
            text={product.text}
            image={product.imageUrl}
          />
        ))}
      </div>
    </section>
  );
};

export default SearchResults;
