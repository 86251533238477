import axios from "axios";

// Create a new instance of Axios
const api = axios.create({
  baseURL: "https://api.seqtor.dk/",  // Replace with Online API URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to inject the token in every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Handle token expiration and refresh logic in response interceptor
api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response && error.response.status === 401) {
    // If token is expired, logout user or handle token refresh logic
    localStorage.removeItem("token");
    window.location.href = "/login";  // Redirect to login if not authenticated
  }
  return Promise.reject(error);
});

export default api;
