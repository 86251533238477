import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LoadingProvider } from "./components/contexts/loadingContext/LoadingContext";
import { ConfirmationModalProvider } from "./components/contexts/confirmationModalContext/ConfirmationModalContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.esm.min.js";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "animate.css/animate.min.css";
import { InformationModalProvider } from "./components/contexts/informationModalContext/InformationModalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <InformationModalProvider>
        <ConfirmationModalProvider>
          <App />
        </ConfirmationModalProvider>
      </InformationModalProvider>
    </LoadingProvider>
  </React.StrictMode>
);