import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "./ProductDetails.scss";

import ph_img1 from "../../content/placeholderImages/product_placeholders/cat_1_prod_1.webp";

const ProductDetails = () => {
  const product = {
    name: "RF Signal Jammer X200",
    description: "The RF Signal Jammer X200 is an advanced jamming device designed for military operations. It effectively blocks a wide range of radio frequencies, ensuring secure communications and operational superiority in the field.",
    specifications: {
      "Frequency Range": "20 MHz - 6 GHz",
      "Power Output": "200 Watts",
      "Weight": "15 kg",
      "Dimensions": "40 x 30 x 15 cm",
      "Operating Temperature": "-20°C to +50°C",
    },
    price: "€12,500",
    imageUrl: ph_img1, // Replace with the actual image URL
  };

  const [theme, setTheme] = useState(getCurrentTheme());

  useEffect(() => {
    const htmlElement = document.querySelector("html");
    const observer = new MutationObserver(() => {
      setTheme(htmlElement.getAttribute("data-bs-theme") || "dark");
    });

    observer.observe(htmlElement, { attributes: true, attributeFilter: ["data-bs-theme"] });

    return () => {
      observer.disconnect();
    };
  }, []);

  const data = {
    labels: ["10 MHz", "100 MHz", "500 MHz", "1 GHz", "2 GHz", "4 GHz", "6 GHz"],
    datasets: [
      {
        label: "Signal Blocking Efficiency (%)",
        data: [85, 90, 92, 95, 93, 90, 88],
        fill: true,
        backgroundColor: theme === "dark" ? "rgba(0,123,255,0.2)" : "rgba(75,192,192,0.2)",
        borderColor: theme === "dark" ? "#007bff" : "#4bc0c0",
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        grid: {
          color: theme === "dark" ? "#444" : "#ddd",
        },
        ticks: {
          color: theme === "dark" ? "#fff" : "#000",
        },
      },
      x: {
        grid: {
          color: theme === "dark" ? "#444" : "#ddd",
        },
        ticks: {
          color: theme === "dark" ? "#fff" : "#000",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: theme === "dark" ? "#fff" : "#000",
        },
      },
    },
    layout: {
      padding: 10,
    },
    backgroundColor: theme === "dark" ? "#2c2c2c" : "#ffffff", // Set background color based on the theme
  };

  return (
    <div className="product-details">
      <div className="hero-image" style={{ backgroundImage: `url(${product.imageUrl})` }}>
        <div className="overlay">
          <h1 className="product-name">{product.name}</h1>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-md-8">
            <h2>Overview</h2>
            <p>{product.description}</p>
          </div>
          <div className="col-md-4">
            <h2>Specifications</h2>
            <ul className="list-unstyled">
              {Object.entries(product.specifications).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="performance-chart my-5">
          <h3 className="text-center">Performance Chart</h3>
          <div style={{ backgroundColor: options.backgroundColor, padding: "20px", borderRadius: "8px" }}>
            <Line data={data} options={options} />
          </div>
        </div>

        <div className="pricing-section text-center mt-5">
          <h2 className="price">Price: {product.price}</h2>
          <button className="btn btn-primary btn-lg">Request a Quote</button>
        </div>
      </div>
    </div>
  );
};

const getCurrentTheme = () => {
  const storedTheme = localStorage.getItem("theme");
  if (storedTheme) {
    return storedTheme;
  }
  const htmlElement = document.querySelector("html");
  return htmlElement.getAttribute("data-bs-theme") || "dark";
};

export default ProductDetails;
